import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import SlantedText from 'Common/SlantedText'
import MeetTeam from 'Common/Team'

const bcorpContent = {
  title: 'Aspiring B Corp',
  body: `
  Societies challenges are not the responsibility of Governments, Non profits and charitable organisations alone.  We believe that all businesses have an important role to play in challenging society to be better, more caring, and more sustainable.
  <br />
  <br />
  Colooder has been founded on principles which align to our application for B-Corps certification.  We passionately believe that a great company is one where profit meets purpose.  That means we’re committed to achieving the highest standards of social and environmental performance.  We aspire to transparency and accountability in our work and to a culture which is positive for our clients, employees, the communities where we work and to the environment.  
  <br />
  <br />
  For further details on B-Corp Certification, 
  see <a href='https://bcorporation.net/about-b-corps'>About B Corps</a>`
}

const meetTeam = {
  title: 'Who’s Who?',
  team: [

    {
      name: 'Mark Guymer​​',
      title: 'CEO',
      image: 'mark',
      social: {
        linkedIn: 'https://www.linkedin.com/in/mark-guymer-06653b/'
      }
    },
    {
      name: 'Syd Nadim',
      title: 'Founder',
      image: 'syd',
      social: {
        linkedIn: 'https://www.linkedin.com/in/sydnadim/'
      }
    },
    {
      name: 'Ned Wills​',
      title: 'Chief Customer Officer',
      image: 'ned',
      social: {
        linkedIn: 'https://www.linkedin.com/in/ned-wills-b4262111/'
      }
    },
    {
      name: 'Phil Jones​​',
      title: 'Non-Exec Director​',
      image: 'phill',
      social: {
        linkedIn: 'https://www.linkedin.com/in/realtime/'
      }
    },
    {
      name: 'Rob Arnold​',
      image: 'rob',
      title: 'Advisor and Clock MD',
      social: {
        linkedIn: 'https://www.linkedin.com/in/arniie/'
      }
    },
    {
      name: 'Jason Treloar​',
      image: 'jason',
      title: 'Commercial Director​',
      social: {
        linkedIn: 'https://www.linkedin.com/in/jasontreloar/'
      }
    },
    {
      name: 'Beth Gavin​​​',
      image: 'beth',
      title: 'Client Services',
      social: {
        linkedIn: 'https://www.linkedin.com/in/beth-gavin-1b5949bb/'
      }
    },
    {
      name: 'Paul Shattock​​​',
      image: 'paul',
      title: 'FD',
      social: {
        linkedIn: '#'
      }
    }
  ]
}

const Team = () => {
  const data = useStaticQuery(graphql`
  query {
    paul: file(relativePath: { eq: "paul.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     beth: file(relativePath: { eq: "beth.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jason: file(relativePath: { eq: "jason.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rob: file(relativePath: { eq: "rob.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phill: file(relativePath: { eq: "phill.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mark: file(relativePath: { eq: "mark.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    syd: file(relativePath: { eq: "syd.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ned: file(relativePath: { eq: "ned.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bcorp: file(relativePath: { eq: "bcorp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)
  return (
    <>
      <SlantedText {...bcorpContent} image={data.bcorp.childImageSharp.fluid} />
      <MeetTeam {...meetTeam} images={data} />
    </>
  )
}

Team.propTypes = {

}

export default Team
