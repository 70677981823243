import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Devices from '../components/Common/Devices'
import Features from "../components/Sections/Features"
import Services from '../components/Sections/Services'
import Team from '../components/Sections/Team'
import Partners from '../components/Sections/Partners'
import Info from '../components/Sections/Info'
import Contact from '../components/Common/Contact'

const IndexPage = () => (
  <Layout>
    <SEO title="Colooder" />
    <Devices />
    <Features />
    <Services />
    <Team />
    <Partners />
    <Info />
    <Contact title="Get in touch" />
  </Layout>
)

export default IndexPage
