import React from "react"
import { string, object, array, bool } from 'prop-types'

import Image from "gatsby-image"
import classNames from "classnames"
import Type from  "Primitive/Type"
import VisuallyHidden from  "Primitive/VisuallyHidden"
import Icon from "Primitive/Icon"

import styles from "./FeaturePanel.module.scss"

const Feature = ({ icon, title, features, color }) => (
  <div style={{ backgroundColor: color }} className={styles.FeatureWrapper}>
    <div className={styles.IconWrapper}>
      <Icon
        type={icon.name}
        width={icon.width}
        height={icon.height}
        a11yText={title}
      />
    </div>
    <div className={styles.FeatureBody}>
      <Type className={styles.FeatureTitle} as="h3" size="title" bold>
        {title}
      </Type>
      {features &&
        features.map((feat, i) => (
          <Type
            className={styles.FeatureText}
            key={`Feature-` + feat + i}
            as="p"
            size="base"
          >
            {feat}
          </Type>
        ))}
    </div>
  </div>
)

const FeaturePanel = ({
  fluidImg,
  heading,
  text,
  features,
  color,
  reverse,
  id,
}) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={classNames(styles.FeaturePanel, reverse && styles.reverse)}
    >
      <VisuallyHidden>
        <span className={styles.Anchor} id={id} />
      </VisuallyHidden>
      <div className={styles.PanelWrapper}>
        <div className={styles.ImageWrapper}>
          <Image loading='lazy' fadeIn={250} className={styles.Image} fluid={fluidImg} alt="" />
        </div>
        <div className={styles.TextWrapper}>
          <Type className={styles.PanelTitle} bold size="titleLarge" as="h2">
            {heading}
          </Type>
          <Type
            className={styles.Text}
            size="base"
            dangerouslySetInnerHTML={{ __html: text }}
            as="p"
          ></Type>
        </div>
      </div>
      <div className={styles.Features}>
        {features &&
          features.map((feat, i) => (
            <Feature
              icon={feat.icon}
              title={feat.title}
              features={feat.features}
              color={feat.color}
              key={feat.title + i}
            />
          ))}
      </div>
    </div>
  )
}

FeaturePanel.propTypes = {
  fluidImg: object,
  heading: string,
  text: string,
  features: array,
  color: string,
  reverse: bool,
  id: string
}

export default FeaturePanel
