import React from "react"

import ServicePanel from 'Common/ServicePanel'

const services = {
  title: "Colooder XMS™ Services",
  subHeading:
    "Feature rich, the XMS maximises event revenues by putting simple customer experience at the heart of its service offering.  XMS integrates traditional CMS with the physical fan experience allowing rights holders to better manage delivery of digital and real life services to their fan community in venue or at home.",
  color: '#41BDD6',
    services: [
    {
      title: "Ticketing and Access Control",
      icon: { name: "ticket", width: 70, height: 70 },
      color: '#3DB6CE',
      features: [
        "Integration of digital ticketing services and Access control.",
        "Intelligent ticket presentation, when it's needed.",
      ],
    },
    {
      title: "Queueing and F&B",
      color: '#3DB6CE',
      icon: { name: "swipestation", width: 55, height: 85 },
      features: ["Integration of Food and Beverage EPOS.", "Queue busting Innovations. ", "Social Distancing mechanisms to eradicate the queue."],
    },
    {
      title: "Buying Merchandise",
      color: '#3DB6CE',
      icon: { name: "shirt", width: 75, height: 60 },
      features: [
        `Integration of Merchandising suppliers.`,
        `In seat delivery & Click and Collect in Store or direct to Home.`,
        `Delivering personalised retail opportunities in store and online.`
      ],
    },
    {
      title: "Communication and Content",
      color: '#38A9BF',
      icon: { name: "note", width: 60, height: 60  },
      features: [`Dynamic Communication platform, for PR, Publishing, Podcasts and radio feeds.`,`Zero latency Ref Feed direct to user devices - coordinated with broadcasters.`, `Personalised Content and Geo Targeting.`],
    },
    {
      title: "Fellow Fan (Icon Voice Services)",
      color: '#38A9BF',
      icon: { name: "persongear", width: 60, height: 70 },
      features: [
        `Integrated voice services connects the fan experience with at home IoT technology.`,
      ],
    },
    {
      title: "Fan Buddy",
      color: '#38A9BF',
      icon: { name: "microphone", width: 47, height: 60 },
      features: [
        `In home match and venue information commentary and voice purchasing. Turn your voice devices into another fan at your side.`,
      ],
    },
    {
      title: "Next Best Action",
      icon: { name: "cms", width: 60, height: 60 },
      color: '#349AAD',
      features: [
        `Personlised suggestions based on fan behaviour delivered right to their device.  Dynamic App control alters design dependent on learnt fan behaviours.`,
      ],
    },
    {
      title: "Business Insight and Real-time Decisioning",
      icon: { name: "idea", width: 45, height: 73 },
      color: '#349AAD',
      features: [
        `Behavioural & purchase data provides rich opportunities for short and long term decision making.`,
      ],
    },
    {
      title: "Match Centre",
      color: '#349AAD',
      icon: { name: "bell", width: 55, height: 60 },
      features: [
        `The XMS utilizes an integrated match centre to distribute live and up to the minute content to web, social channels, push notifications and more. The Match Centre is integrated with stats providers to automatically and dynamically deliver exciting content to phones, wearables, website, and voice devices.`,
      ]
    },
  ],
}

const Services = () => {
  return <ServicePanel {...services} />
}

Services.propTypes = {}

export default Services
