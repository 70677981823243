import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import FeaturePanel from 'Common/FeaturePanel'

const fans = {
  heading: 'Easier For Fans',
  text: 'Colooder is unique in bringing together all the services required to provide the ultimate day out.',
  color: '#41BDD6',
  id: 'fans',
  features: [
    {
      icon: {name: 'buy', width: 65, height: 95},
      title: 'Easier to purchase',
      color: '#41B6CE',
      features: [
        'Tickets',
        'Food & Drink',
        'Merchandise',
        'Subscriptions & Content'
      ]
    },
    {
      icon: {name: 'navigation', width: 70, height: 70},
      color: '#38A9BF',
      title: 'Easy to get around',
      features: [
        'Access Control',
        'Wayfinding',
        'Queue Busting',
      ]
    },
    {
      icon: {name: 'discover', width: 70, height: 70},
      color: '#349AAD',
      title: 'Easy to discover',
      features: [
        'What’s going on',
        'Where things are happening',
        'Personalised Content',
      ]
    },
    {
      icon: {name: 'user', width: 55, height: 70},
      color: '#308B9D',
      title: 'Easy to enjoy',
      features: [
        'Rewarding Loyalty',
        `Enhanced in venue and at 
        home services`,
        'Discounts and Offers',
        'Ethical White-Label Betting',
        'Personalised Experiences'
      ]
    }
  ]
}

const digital = {
  heading: 'Easier For Rights Holders',
  text: `Colooder provides Rights Holders gateway control of all digital services provided by your chosen suppliers and service partners.
  <br />
  <br />
  Rights Holders define and control the end to end digital customer journey from consideration 
  to event completion, control of data flow on transactions and customer behaviour.`,
  color: '#31122A',
  id: 'rights-holders',
  features: [
    {
      icon: {name: 'chart', width: 80, height: 55},
      title: 'Data',
      color: '#682959',
      features: [
        'Customer Single Sign On (SSO)',
        'End to end customer journey management',
        'Advanced Behavioural Insight',
        'Secure and Compliant'
      ]
    },
    {
      icon: {name: 'ui', width: 70, height: 85},
      color: '#581E4A',
      title: 'Beautifully Designed',
      features: [
        'Smart optimisation for any device or browser',
        'Customised Branding',
        'Customer Centric Approach',
        'Powerful Search',
      ]
    },
    {
      icon: {name: 'clock', width: 80, height: 80},
      color: '#401736',
      title: 'Just in Time',
      features: [
        'Personalised Customer Services',
        'Dynamic offers and Pricing',
        `Geo targeting`
      ]
    },
    {
      icon: {name: 'person', width: 65, height: 85},
      color: '#31122A',
      title: 'Activation',
      features: [
        'Enhanced Sponsorship Solutions',
        `Stronger Commercial Partnerships `,
        `Innovation for new Commercial Opportunities`
      ]
    }
  ]
}

const Features = () => {
  const data = useStaticQuery(graphql`
  query {
    fans: file(relativePath: { eq: "fans.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1366) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    digital: file(relativePath: { eq: "digital.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1366) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

  return (
    <>
      <FeaturePanel {...fans} fluidImg={data.fans.childImageSharp.fluid} />
      <FeaturePanel {...digital} reverse fluidImg={data.digital.childImageSharp.fluid} />
    </>
  )
}

Features.propTypes = {

}

export default Features
