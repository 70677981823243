import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PartnersC from "Common/Partners"

const partnersContent = {
  title: "Powerful Partnerships ",
  body: `
  Powerful partnerships between Teams, Events, Venues, leagues and fans enabled through digital interfaces enhance fan enjoyment, removing barriers which frustrate purchase decisions, allowing fans to enjoy the entertainment instead of waiting for services.`,
  partners: [
    {
      brand: "SecuTix",
      url: "https://www.secutix.com/",
      title: "Ticketing Partner​​",
      description: "SecuTix 360° the cloud ticketing engagement platform.​",
      image: "secutix",
    },
    {
      brand: "Opta",
      url: "https://www.optasports.com/",
      title: "Sports Data",
      description:
        "World leader in sports data to bring your event and content to life.​",
      image: "opta",
    },
    {
      brand: "Landways",
      url: "https://landways.com/",
      title: "Digital Infrastructure​​",
      description:
        "Providing digital infrastructure to revolutionise the way popular venues connect and operate their venues.​",
      image: "landways",
    },
    {
      brand: "Clock​",
      url: "https://www.clock.co.uk/",
      title: "Delivery Partner​​",
      description:
        "Clock have over two decades of experience delivering websites and apps for major brands in the media, entertainment and leisure sectors.​",
      image: "clockLogo",
    },
    {
      brand: "Gloucester Rugby Club​",
      url: "https://www.gloucesterrugby.co.uk/",
      title: "Premier League Rugby Club​​​",
      description: `Gloucester's digital platforms, making entertainment easy and driving increased revenue.`,
      image: "gloucester",
    },
    {
      brand: "SwipeStation​",
      url: "http://www.swipestation.co.uk/",
      title: "Food & Beverage Partner​​​",
      description:
        "SwipeStation delivers a proven Click & Collect service for refreshments (and more).",
      image: "swipestation",
    },
  ],
}

const Partners = props => {
  const data = useStaticQuery(graphql`
    query {
      secutix: file(relativePath: { eq: "secutix.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      opta: file(relativePath: { eq: "opta2.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      landways: file(relativePath: { eq: "landways.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      clockLogo: file(relativePath: { eq: "clock-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gloucester: file(relativePath: { eq: "gloucester.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      swipestation: file(relativePath: { eq: "swipestation.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return <PartnersC {...partnersContent} images={data} />
}

Partners.propTypes = {}

export default Partners
