import React from "react"

import Container from  "Primitive/Container"
import Type from  "Primitive/Type"
import TextControl from "Primitive/TextControl"
import FieldTemplate from "Primitive/FieldTemplate"
import ButtonStandard from "Primitive/ButtonStandard"
import Icon from "Primitive/Icon"
import SmartLink from "Primitive/SmartLink"
import VisuallyHidden from  "Primitive/VisuallyHidden"

import styles from "./Contact.module.scss"

const Contact = ({ title }) => {
  return (
    <Container className={styles.Contact} gutter>
      <VisuallyHidden>
        <span className={styles.Anchor} id='contact-us' />
      </VisuallyHidden>
      <Type as="h2" size="titleLarge" className={styles.Title}>
        {title}
      </Type>
      <div className={styles.SocialIcons}>
        <SmartLink className={styles.SocialIcon} href="https://twitter.com/colooder">
          <Icon
            className={styles.IconTw}
            type="twitter"
            height={30}
            width={20}
            a11yText="Twitter"
          />
        </SmartLink>
        <SmartLink className={styles.SocialIcon} href="https://www.facebook.com/Colooder/">
          <Icon
            className={styles.IconFb}
            type="facebook"
            height={30}
            width={10}
            a11yText="Facebook"
          />
        </SmartLink>
        <SmartLink className={styles.SocialIcon} href="https://www.linkedin.com/company/colooder/">
          <Icon
            className={styles.IconLi}
            type="linkedin"
            height={30}
            width={20}
            a11yText="LinkedIn"
          />
        </SmartLink>
      </div>
      <div className={styles.CompanyDetails}>
        <div className={styles.DetailWrapper}>
          <Icon
            className={styles.Icon}
            type="phone"
            width={25}
            height={25}
            a11yText="Phone"
          />
          <a href="tel:+44(0)1923261166">+44 (0)1923 261166</a>
        </div>
        <div className={styles.DetailWrapper}>
          <Icon
            className={styles.Icon}
            type="email"
            width={30}
            height={25}
            a11yText="Phone"
          />
          <a href="mail:hello@colooder.co.uk">hello@colooder.co.uk</a>
        </div>
        <div className={styles.DetailWrapper}>
          <Icon
            className={styles.Icon}
            type="location"
            width={20}
            height={30}
            a11yText="Phone"
          />
          <a href="https://goo.gl/maps/juCgwg8Ho9VNxnQ4A" target="_blank noreferer">
            The Old School House, Bridge Road, Hunton Bridge, Kings Langley,
            Hertfordshire. WD4 8RQ
          </a>
        </div>
      </div>
      <form
        className={styles.Form}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className={styles.FormInner}>
          <div className={styles.FormDetails}>
            <div className={styles.Form1}>
              <FieldTemplate label="Full name" required controlName="name">
                <TextControl
                  name="name"
                  placeholder="Your full name"
                  type="text"
                  required
                />
              </FieldTemplate>
              <FieldTemplate label="Email" required controlName="email">
                <TextControl
                  name="email"
                  placeholder="Your email address"
                  type="email"
                  required
                />
              </FieldTemplate>
              <FieldTemplate label="Telephone" controlName="phone">
                <TextControl
                  name="phone"
                  placeholder="Your phone number"
                  type="tel"
                />
              </FieldTemplate>
            </div>
            <div className={styles.Form2}>
            <FieldTemplate label="Organisation" controlName="organisation">
              <TextControl
                name="organistaion"
                placeholder="Your organisation"
                type="text"
              />
            </FieldTemplate>
            <FieldTemplate label="Address" controlName="address">
              <TextControl
                name="address"
                placeholder="Your address"
                type="text"
              />
            </FieldTemplate>
            <FieldTemplate
              label="Company registration details"
              controlName="registrationDetails"
            >
              <TextControl
                name="registrationDetails"
                placeholder="Your company registration details"
                type="text"
              />
            </FieldTemplate>
            </div>
          </div>
          <div className={styles.FormMessage}>
            <FieldTemplate
              label="Message"
              required
              controlName="message"
            >
              <TextControl
                name="message"
                placeholder="Your message"
                multiLine
                rows={8}
                required
              />
            </FieldTemplate>
            <ButtonStandard className={styles.SubmitButton} type="submit">
              <Type size="base" demi>
                SUBMIT
              </Type>
            </ButtonStandard>
          </div>
        </div>
      </form>
    </Container>
  )
}

Contact.propTypes = {}

export default Contact
