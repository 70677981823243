import React from "react"
import { string } from "prop-types"
import Type from "Primitive/Type"
import Container from "Primitive/Container"
import VisuallyHidden from "Primitive/VisuallyHidden"

import styles from "./SlantedText.module.scss"

const SlantedText = ({ title, body }) => {
  return (
    <Container gutter className={styles.SlantedText}>
      <VisuallyHidden>
        <span className={styles.Anchor} id="about-us" />
      </VisuallyHidden>
      <div className={styles.Split} />
      <Type size="titleLarge" className={styles.Title} as="h2">
        {title}
      </Type>
      <div className={styles.BodyWrapper}>
        <Type
          size="base"
          className={styles.Body}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </Container>
  )
}

SlantedText.propTypes = {
  title: string,
}

export default SlantedText
