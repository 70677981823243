import React from "react"
import { string, array } from "prop-types"

import Type from  "Primitive/Type"
import Container from  "Primitive/Container"
import Icon from "Primitive/Icon"
import SmartLink from "Primitive/SmartLink"
import Image from "gatsby-image"

import styles from "./Team.module.scss"

const Team = ({ title, team, images }) => {
  return (
    <Container gutter className={styles.Team}>
      <Type size="titleLarge" className={styles.Title} as="h2">
        {title}
      </Type>
      <div className={styles.TeamWrapper}>
        {team &&
          team.map((member, i) => (
            <SmartLink href={member.social.linkedIn} className={styles.TeamMember} key={member.name + i}>
              <div className={styles.ImageWrapper}>
                <Image loading='lazy' fadeIn={250} className={styles.Image} fluid={images[member.image].childImageSharp.fluid} alt={member.name} />
              </div>
              <div className={styles.Social} >
                <Icon className={styles.Icon} type='linkedinsq' width={20} height={20} a11yText='Linkedin'/>
              </div>
              <Type size="menu" className={styles.Name}>{member.name}</Type>
              <Type size="small" className={styles.Position}>{member.title}</Type>
            </SmartLink>
          ))}
      </div>
    </Container>
  )
}

Team.propTypes = {
  title: string,
  team: array,
  images: array
}

export default Team
