import React from "react"
import { array, string } from "prop-types"

import Type from "Primitive/Type"
import Container from "Primitive/Container"
import SmartLink from "Primitive/SmartLink"
import VisuallyHidden from "Primitive/VisuallyHidden"
import classNames from "classnames"
import Image from "gatsby-image"

import styles from "./Partners.module.scss"

const Partners = ({ title, body, partners, images }) => (
  <Container gutter className={styles.Partners}>
    <VisuallyHidden>
      <span className={styles.Anchor} id="partners" />
    </VisuallyHidden>
    <Type size="titleLarge" className={styles.Title} as="h2">
      {title}
    </Type>
    <Type size="base" className={styles.Body} as="p">
      {body}
    </Type>
    <div className={styles.PartnersWrapper}>
      {partners &&
        partners.map((partner, i) => (
          <SmartLink
            href={partner.url}
            key={partner.brand + i}
            className={styles.Partner}
          >
            <div
              className={classNames(styles.ImageWrapper, styles[partner.image])}
            >
              {images[partner.image] && (
                <Image
                  loading="lazy"
                  fadeIn={250}
                  className={styles.Image}
                  fluid={images[partner.image].childImageSharp.fluid}
                  alt={partner.brand}
                />
              )}
            </div>
            <Type as="h3" size="titleMedium" className={styles.Brand}>
              {partner.brand}
            </Type>
            <Type size="titleMedium" className={styles.PartnerTitle}>
              {partner.title}
            </Type>
            <Type size="base" className={styles.Description}>
              {partner.description}
            </Type>
          </SmartLink>
        ))}
    </div>
  </Container>
)

Partners.propTypes = {
  title: string,
  body: string,
  partners: array,
  images: array,
}

export default Partners
