import React from "react"

import InfoPanel from "Common/InfoPanel"

const services = {
  sections: [
    {
      color: "#3DB6CE",
      content: [
        {
          title: "Fan Engagement always on",
          html: `
            • News tiering: exclusive and latest news, members only news 
            <br />
            • Match day centre that engages with fans wherever they are: app, website, social, push.
            <br />
            • Quizzes
            <br /> 
            • Competitions
            <br /> 
            • Player of the match voting
            <br /> 
            • Fan gallery
            <br /> 
            • Score predictor
            <br /> 
            • Big screen integrations
            <br /> 
            • Ref feed
            <br /> 
            • Loyalty
            <br />
            `,
        },
      ],
    },
    {
      color: "#38A9BF",
      content: [
        {
          title: "Commercial revenue always on",
          html: `
            • Merchandise
            <br />
            • Click & Collect
            <br />
            • Click & Deliver to seat
            <br />
            • Click & Deliver to home
            <br />
            • Click & Access to premium content (subscription based services)
            <br />
            • Brand partnership solutions through bespoke, data-driven activations
            <br />
            • Raffle
            <br />
            • Betting
          `,
        },
      ],
    },
    {
      color: "#349AAD",
      content: [
        {
          title: "Communicate always on",
          html: `
          • Personalisation
          <br />
          • Email
          <br /> 
          • Push notifications 
          <br />
          • Triggered messaging 
          `,
        },
        {
          title: "Data always on",
          html: `
            • Track and gain insights and understand your fans behaviour as they engage with your platforms
          `,
        },
      ],
    },
  ],
}

const Info = () => {
  return <InfoPanel {...services} />
}

Info.propTypes = {}

export default Info
