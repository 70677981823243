import React from "react"
import { array } from "prop-types"

import Type from  "Primitive/Type"
import Container from "../../Primitive/Container"

import styles from "./InfoPanel.module.scss"

const InfoPanel = ({ sections }) => {
  return (
    <Container className={styles.InfoPanel}>
      {sections &&
        sections.map((section, i) => (
          <div
            style={{ backgroundColor: section.color }}
            key={`section-${i}`}
            className={styles.Wrapper}
          >
            {section.content &&
              section.content.map((content, i) => (
                <div className={styles.ContentWrapper} key={content.title + i}>
                  <Type size="title" as="h3" className={styles.ContentTitle}>
                    {content.title}
                  </Type>
                  <Type
                    size="base"
                    as="p"
                    className={styles.Content}
                    dangerouslySetInnerHTML={{ __html: content.html }}
                  />
                </div>
              ))}
          </div>
        ))}
    </Container>
  )
}
InfoPanel.propTypes = {
  sections: array,
}

export default InfoPanel
