import React from "react"
import { Parallax } from 'react-scroll-parallax';
import Container from  "Primitive/Container"
import Type from  "Primitive/Type"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Devices.module.scss"

const Devices = () => {
  const data = useStaticQuery(graphql`
    query {
      devices: file(relativePath: { eq: "devices.png" }) {
        childImageSharp {
          fluid(maxWidth: 1366) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Container className={styles.Container} gutter spacious center>
      <div className={styles.Devices}>
        <Parallax y={[10, -10]} className={styles.TextWrapper}>
          <Type size="displayLarge" as="h2">
            Connected Experiences
          </Type>
          <Type size="base" as="p">
            Device agnostic, Colooder connects the digital experience to enhance
            the fan's enjoyment of their passion at an event and every day.
          </Type>
          <br />
          <Type size="base" as="p">
            Easier enjoyment drives greater revenue.
          </Type>
        </Parallax>
        <Parallax  y={[20, -20]} className={styles.ImageWrapper}>
          <div  className={styles.Image}>
            <Img fluid={data.devices.childImageSharp.fluid} />
          </div>
          <Type className={styles.DeliverPartner} size="small" as="p">
            Clock delivery partner
          </Type>
        </Parallax >
      </div>
    </Container>
  )
}

export default Devices
