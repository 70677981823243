import React from "react"
import Container from "Primitive/Container"
import Type from "Primitive/Type"
import Icon from "Primitive/Icon"
import VisuallyHidden from "Primitive/VisuallyHidden"
import styles from "./ServicePanel.module.scss"
import { string, array } from "prop-types"

const Service = ({ icon, title, features, color }) => (
  <div style={{ backgroundColor: color }} className={styles.Service}>
    <Type className={styles.ServiceTitle} size="title" as="h3" bold>
      {title}
    </Type>
    <div className={styles.Feautres}>
      {features &&
        features.map((feat, i) => (
          <Type
            className={styles.Feature}
            size="baseMedium"
            as="p"
            key={feat + i}
          >
            {feat}
          </Type>
        ))}
    </div>
    <Icon
      className={styles.Icon}
      type={icon.name}
      width={icon.width}
      height={icon.height}
      a11yText={title}
    />
  </div>
)

const ServicePanel = ({ title, subHeading, services, color }) => {
  return (
    <Container className={styles.ServicePanel}>
      <VisuallyHidden>
        <span className={styles.Anchor} id="colooder-xms" />
      </VisuallyHidden>
      <Type size="titleLarge" className={styles.Title} as="h2" bold>
        {title}
      </Type>
      <Type size="base" className={styles.SubHeading} as="h3">
        {subHeading}
      </Type>
      <div className={styles.PanelWrapper}>
        {services &&
          services.map((serivce, i) => (
            <Service {...serivce} key={serivce.title + i} />
          ))}
      </div>
    </Container>
  )
}

ServicePanel.propTypes = {
  title: string,
  subHeading: string,
  services: array,
  color: string,
}

export default ServicePanel
